import type { PaAPI } from '@rialtic/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { useActiveUser } from './activeUser'
import { useEditor } from './editor'

const createErrorMessage = (error: any): string => {
  const message =
    typeof error === 'string' ? error : error.data?.message || error.message

  if (message === 'Prisma Known Error' && error.data.error) {
    const paragraphs = `${error.data.error}`.split('\n')

    return paragraphs.pop()?.trim() || message
  }
  return message
}

export const useApi = defineStore('api', {
  state: () => {
    const { $auth0Ready, $datadog } = useNuxtApp()
    const apiClient = useApiClient()

    const $apiFetch = async <T>(url: string, opts?, bareApi = false) => {
      await $auth0Ready()

      return apiClient.fetch<T>(url, opts)
    }

    const editTypeState = reactive({
      error: '',
      loading: false,
      data: [] as PaAPI.Get['/edit-type'],
    })

    const editType = {
      getMany: async () => {
        editTypeState.error = ''
        editTypeState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Get['/edit-type']>('/edit-type', {})
          editTypeState.data = data
        } catch (error) {
          $datadog.addError(error)
          editTypeState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          editTypeState.loading = false
        }
      },
    }

    const engineState = reactive({
      error: '',
      data: [] as PaAPI.Get['/engine'],
      loading: false,
    })

    const engine = {
      create: async (engine: PaAPI.PostBody['/engine']) => {
        engineState.loading = true

        const newEngine = {
          name: engine.name,
        }
        try {
          const data = await $apiFetch<PaAPI.Post['/engine']>('/engine/', {
            method: 'POST',
            body: newEngine,
          })
          engineState.data.push(data)
          return data
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error)

          engineState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          engineState.loading = false
        }
      },

      delete: async (id: string) => {
        engineState.error = ''
        engineState.loading = true

        try {
          await $apiFetch<PaAPI.Delete['/engine/{id}']>(`/engine/${id}`, {
            method: 'DELETE',
          })
          engineState.data = engineState.data.filter((item) => item.id !== id)
        } catch (error) {
          $datadog.addError(error)
          engineState.error =
            error instanceof Error ? error.message : (error as string)
          throw new Error(error.message)
        } finally {
          engineState.loading = false
        }
      },

      getMany: async () => {
        engineState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Get['/engine']>('/engine', {})
          engineState.data = data
        } catch (error) {
          $datadog.addError(error)
          engineState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          engineState.loading = false
        }
      },

      update: async (updatedEngine: PaAPI.PutBody['/engine/{id}'], id: string) => {
        engineState.error = ''
        engineState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Put['/engine/{id}']>(`/engine/${id}`, {
            method: 'PUT',
            body: updatedEngine,
          })
          engineState.data = engineState.data.map((item) =>
            item.id === id ? { ...item, ...updatedEngine } : item,
          )

          return data
        } catch (error) {
          $datadog.addError(error)
          engineState.error =
            error instanceof Error ? error.message : (error as string)
          await engine.getMany()
          throw new Error(error.message)
        } finally {
          engineState.loading = false
        }
      },
    }

    const policyState = reactive({
      error: '',
      loading: false,
      data: [] as PaAPI.Get['/policy'],
    })

    const policyEditTypesState = reactive({
      error: '',
      loading: false,
      data: [] as PaAPI.Get['/policy-edit-type'],
    })

    const policy = {
      editType: {
        getMany: async () => {
          policyEditTypesState.error = ''
          policyEditTypesState.loading = true

          try {
            const data =
              await $apiFetch<PaAPI.Get['/policy-edit-type']>('/policy-edit-type')
            policyEditTypesState.data = data
          } catch (error) {
            $datadog.addError(error)
            policyEditTypesState.error =
              error instanceof Error ? error.message : (error as string)
          } finally {
            policyEditTypesState.loading = false
          }
        },
      },

      addReview: async (
        policy_id: string,
        status_id: string,
      ): Promise<PaAPI.Get['/policy/{id}'] | undefined> => {
        const activeUser = useActiveUser()
        policyState.loading = true
        try {
          const body: PaAPI.PostBody['/policy-review'] = {
            policy: policy_id,
            status: status_id,
            reviewer: activeUser.email,
          }
          await $apiFetch<PaAPI.Post['/policy-review']>('/policy-review', {
            method: 'POST',
            body,
          })
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error)

          policyState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          policyState.loading = false
          await policy.get(policy_id)
        }

        return policyState.data.find(({ id }) => id === policy_id)
      },

      create: async (policy: PaAPI.PostBody['/policy']) => {
        policyState.loading = true
        try {
          const data = await $apiFetch<PaAPI.Post['/policy']>('/policy', {
            method: 'POST',
            body: policy,
          })

          policyState.data.push(data)

          const editor = useEditor()
          editor.pushToRecentPolicy(data.id)

          return data
        } catch (error) {
          if (error?.data?.message === 'Prisma Known Error') {
            const prismaError = error.data.error
            $datadog.addError(error, {
              type: 'prisma_error',
              policy,
              prisma: prismaError.join('\n'),
            })
            if (
              prismaError.includes(
                '  Unique constraint failed on the fields: (`name`)',
              )
            ) {
              policyState.error = 'Policy name needs to be unique'
              throw new Error(policyState.error)
            }
          }

          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error, { policy })

          policyState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          policyState.loading = false
        }
      },

      get: async (id: string) => {
        policyState.error = ''
        policyState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Get['/policy/{id}']>(`/policy/${id}`, {
            params: { include: true },
          })

          policyState.data = policyState.data.length
            ? policyState.data.map((item) =>
                item.id === data.id ? data : item,
              )
            : [data]

          return data
        } catch (error) {
          $datadog.addError(error)
          policyState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          policyState.loading = false
        }
      },

      getMany: async () => {
        policyState.error = ''
        policyState.loading = true

        try {
          const tStart = performance.now()
          const data = await $apiFetch<PaAPI.Get['/policy']>('/policy', {
            params: { include: true },
          })
          const tEnd = performance.now()
          $datadog.addTiming('fetch_policy_many', tEnd - tStart)

          policyState.data = data
        } catch (error) {
          $datadog.addError(error)
          policyState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          policyState.loading = false
        }
      },

      update: async (updates: PaAPI.PutBody['/policy/{id}'], id: string) => {
        policyState.error = ''
        policyState.loading = true

        try {
          $datadog.addAction('update_policy', {
            id,
            updates,
          })
          const buildUpdatedPolicy = (current: PaAPI.Get['/policy/{id}'], updates: PaAPI.PutBody['/policy/{id}']) => {
            const extras = {} as PaAPI.PutBody['/policy/{id}']

            if (updates.topic_id) {
              extras.topic = topicState.data.find(
                ({ id }) => id === updates.topic_id,
              )?.id
            }

            if (updates.edit_types) {
              console.log(updates.edit_types)
            }

            return {
              ...current,
              ...updates,
              ...extras,
            } as PaAPI.Get['/policy/{id}']
          }
          const policyToUpdate = policyState.data.find((item) =>
            item.id === id
          )

          if (policyToUpdate)
          policy.updateState(buildUpdatedPolicy(policyToUpdate, updates))

          if (updates.edit_types) {
            updates.edit_types = updates.edit_types.map(({ edit_type_id }) => ({
              edit_type_id,
            }))
          }
          const data = await $apiFetch<PaAPI.Put['/policy/{id}']>(`/policy/${id}`, {
            method: 'PUT',
            body: updates,
          })

          return data
        } catch (error) {
          const errorMessage = createErrorMessage(error)
          $datadog.addError(error)

          policyState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          policyState.loading = false
        }
      },

      updateState(policyUpdates: PaAPI.Get['/policy/{id}']) {
        policyState.data = policyState.data.map((item) =>
          item.id === policyUpdates.id ? { ...item, ...policyUpdates } : item,
        )
      }
    }

    const reviewStatusState = reactive({
      error: '',
      loading: false,
      data: [] as PaAPI.Get['/review-status'],
    })

    const reviewStatusCollection = computed(() =>
      [...reviewStatusState.data].reduce<Record<string, string>>((state, { id, status }) => {
        state[id] = status
        return state
      }, {}),
    )

    const reviewStatus = {
      getMany: async () => {
        reviewStatusState.error = ''
        reviewStatusState.loading = true
        try {
          if (
            Array.isArray(reviewStatusState.data) &&
            reviewStatusState.data.length
          )
            return reviewStatusState.data

          const data = await $apiFetch<PaAPI.Get['/review-status']>('/review-status', {
            params: { include: true },
          })
          reviewStatusState.data = data
          return data
        } catch (error) {
          $datadog.addError(error)
          reviewStatusState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          reviewStatusState.loading = false
        }
      },
    }

    const sourceDocumentState = reactive({
      error: '',
      loading: false,
      data: [] as PaAPI.Get['/source-document'],
    })

    const sourceDocument = {
      get: async (id: string) => {
        sourceDocumentState.error = ''
        sourceDocumentState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Get['/source-document/{id}']>(
            `/source-document/${id}`,
            {
              params: { include: true },
            },
          )

          sourceDocumentState.data = sourceDocumentState.data.length
            ? sourceDocumentState.data.map((item) =>
                item.hash === data.hash ? data : item,
              )
            : [data]

          return data
        } catch (error) {
          $datadog.addError(error)
          sourceDocumentState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          sourceDocumentState.loading = false
        }
      },

      getMany: async () => {
        sourceDocumentState.error = ''
        sourceDocumentState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Get['/source-document']>(
            '/source-document',
            {
              params: {
                include: true,
              },
            },
          )
          sourceDocumentState.data = data.filter((item) => {
            return /^https:\/\/api-worker/g.test(item.internal_url)
          })
        } catch (error) {
          $datadog.addError(error)
          sourceDocumentState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          sourceDocumentState.loading = false
        }
      },

      update: async (updates: PaAPI.PutBody['/source-document/{id}'], hash: string) => {
        sourceDocumentState.error = ''
        sourceDocumentState.loading = true

        try {
          sourceDocumentState.data = sourceDocumentState.data.map((item) =>
            item.hash === hash ? { ...item, ...updates } as PaAPI.Get['/source-document/{id}'] : item,
          )

          $datadog.addAction('update_source_document', {
            id: hash,
            updates,
          })

          const data = await $apiFetch<PaAPI.Put['/source-document/{id}']>(
            `/source-document/${hash}`,
            {
              method: 'PUT',
              body: updates,
            },
          )

          return data
        } catch (error) {
          $datadog.addError(error)
          sourceDocumentState.error =
            error instanceof Error ? error.message : (error as string)
          await sourceDocument.getMany()
        } finally {
          sourceDocumentState.loading = false
        }
      },
    }

    const topicState = reactive({
      error: '',
      data: [] as PaAPI.Get['/topic'],
      loading: false,
    })

    const topic = {
      create: async (topic: PaAPI.PostBody['/topic']) => {
        topicState.loading = true

        const newTopic = {
          topic: topic.topic,
        }
        try {
          const data = await $apiFetch<PaAPI.Post['/topic']>('/topic/', {
            method: 'POST',
            body: newTopic,
          })
          topicState.data.push(data)
          return data
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : (error as string)
          $datadog.addError(error)

          topicState.error = errorMessage
          throw new Error(errorMessage)
        } finally {
          topicState.loading = false
        }
      },

      delete: async (id: string) => {
        topicState.error = ''
        topicState.loading = true

        try {
          await $apiFetch<PaAPI.Delete['/topic/{id}']>(`/topic/${id}`, {
            method: 'DELETE',
          })
          topicState.data = topicState.data.filter((item) => item.id !== id)
        } catch (error) {
          $datadog.addError(error)
          topicState.error =
            error instanceof Error ? error.message : (error as string)
          throw new Error(topicState.error)
        } finally {
          topicState.loading = false
        }
      },

      getMany: async () => {
        topicState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Get['/topic']>('/topic', {})
          topicState.data = data
        } catch (error) {
          $datadog.addError(error)
          topicState.error =
            error instanceof Error ? error.message : (error as string)
        } finally {
          topicState.loading = false
        }
      },

      update: async (updatedTopic: PaAPI.PutBody['/topic/{id}'], id: string) => {
        topicState.error = ''
        topicState.loading = true

        try {
          const data = await $apiFetch<PaAPI.Put['/topic/{id}']>(`/topic/${id}`, {
            method: 'PUT',
            body: updatedTopic,
          })
          topicState.data = topicState.data.map((item) =>
            item.id === id ? { ...item, ...updatedTopic } : item,
          )

          return data
        } catch (error) {
          $datadog.addError(error)
          topicState.error =
            error instanceof Error ? error.message : (error as string)
          await topic.getMany()
          throw new Error(topicState.error)
        } finally {
          topicState.loading = false
        }
      },
    }

    return {
      fetch: $apiFetch,

      editType,
      editTypeState,

      engine,
      engineState,

      policy,
      policyState,
      policyEditTypesState,

      reviewStatus,
      reviewStatusState,
      reviewStatusCollection,

      sourceDocument,
      sourceDocumentState,

      topic,
      topicState,
    }
  },

  getters: {
    getPolicyById: (state) => {
      return (policyId: string) =>
        state.policyState.data.find((policy) => policy.id === policyId) ||
        ({} as PaAPI.Get['/policy/{id}'])
    },
  },

  actions: {
    setPolicies(policies: PaAPI.Get['/policy']) {
      this.policyState.data = policies
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useApi, import.meta.hot))
